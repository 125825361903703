.chat-page{
    /* border: 2px solid red; */
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: space-between;
}
.left-panel{
    /* border: 2px solid black; */
    width: 20vw;
    height: 100vh;
    border-top: none;
    border-left: none;
    border-bottom: none;
    background-color: #202123;
    /* color: white; */
    color: #ECECF1;
}

.right-panel{
    /* border: 2px solid black; */
    width: 100vw;
    height: 100vh;
    
    
}
.active-users{
    /* border: 2px solid black; */
    height: 50vh;
    border-top: none;
    border-left: none;
    border-right: none;
    /* padding-top: 1rem; */
    /* overflow-y: scroll; */
    
}
.active-users p{
    /* font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
    /* font-size: .9rem; */
}
hr{
    width: 12rem;
    /* height: 0.1px; */
    margin-left: .4rem;

}
.sub-head{
    margin-top: 5px;
    margin-left: 10px;
    border: 1px solid white;
    width: fit-content;
    border-left: none;
    border-right: none;
    border-top: none;
}
.user-name{
    margin-left:.5rem ;
    margin-top: .5rem;
}
.user-req{
    /* border: 2px solid yellow; */
    height: 40vh;
    /* overflow-y: scroll; */
    
}
.tmp-user-req{
    display: flex;
    gap: 12px;
}
.tmp-user-req button{
    border-radius: 7px;
    border: none;
    height: 1.4rem;
    width: 1.4rem;
}
.chat-cont{
    /* border: 2px solid green; */
    height: 85vh;
    overflow-y: scroll;
    padding: 1rem 4rem;
}
.msg-form-cont form{
    /* border: 2px solid black; */
    display: flex;
    
    /* gap: 2rem; */
    
}
.msg-form-cont form input[type=text]{
    width: 120vh;
    height: 40px;

}

.msg-form-cont form input[type=submit]{
    width: 20vh;
    height: 40px;

}
.chat{
    /* border: 2px solid red; */
    margin: 10px 0px;
    padding: 1px 10px;
    border-radius: 4px;
    background-color: rgb(237, 237, 237);
}

.chat-user-name{
    font-size: 11px;
    font-weight: bold;
}
.chat-user-msg{
    padding-left: 10px;
    white-space:pre-line;
    /* word-wrap: break-word; */
    font-size: 13px;
    max-width: 70vw;
    padding-bottom: 5px;
}
textarea{
    margin-left: 2rem ;
    /* height: 2.5rem; */
    border-radius: 7px;
    border: none;
    background-color: rgb(229, 229, 229);
    /* text-indent: 10px; */
    padding-top: 7px;
    padding-left: 7px;
    resize: none;
}
textarea::placeholder{
    margin: auto;
    /* width: fit-content; */
    /* border: 2px solid red; */
    text-align: center;
    
}
textarea:focus{
    outline: none;
}
.msg-form-cont form input[type=file]{
    border: 2px solid red;
    display: none;
}
/* .msg-form-cont form label{
    padding-left: 2rem ;
} */
.msg-form-cont form label i{
    font-size: 2rem;
    padding-left: 2rem;
    padding-top: 5px;
}

.chat-page .left-panel .logo{
    font-size: 2rem;
    /* margin: 0.5rem 1rem 1rem 3rem ; */
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 800;
    border: 1px solid white;
    text-align: center;
    border-left: none;
    border-right: none;
    border-top: none;
    padding-bottom:10px ;
    padding-top: 10px;
}
